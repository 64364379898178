import React from "react";
import { theme } from "@sqymagma/theme";
import { format } from "date-fns";

import { MiniWidget } from "@elements";
import { ReactComponent as WeLogSvg } from "@images/welog.svg";

const es = require("date-fns/locale/es").default;

const WeLog = () => {
  const weekNumber = format(new Date(), "w", {
    locale: es,
    firstWeekContainsDate: 7,
  });

  const handleAction = () => {
    window.open(
      "https://design.penpot.app/#/workspace/f5fe9278-89db-81e9-8004-f5c398ddbd7d/20eda0ae-b2ea-80c7-8004-f5f527e5dbe0?page-id=a05f873c-85e5-8074-8004-f5f67e792da7",
      "_blank"
    );
  };

  return (
    <MiniWidget
      title="WeLog"
      description={`Semana ${weekNumber}`}
      color={theme("colors.light")}
      icon="clipboard"
      action={handleAction}
      image={WeLogSvg}
    />
  );
};

export default WeLog;
