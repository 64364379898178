const GROUPS = [
  {
    name: "Charlie & Luisa",
    participants: [
      "luisa.rodriguez@secuoyas.com",
      "carlos.denova@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Clara & Isabella",
    participants: [
      "clara.iglesias@secuoyas.com",
      "isabel.otero@secuoyas.com"
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Carmen & Olga",
    participants: [
      "carmen.martin@secuoyas.com",
      "olga.casajuana@secuoyas.com"
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Dani S. & Miguel",
    participants: [
      "miguel.tallon@secuoyas.com",
      "daniel.serrano@secuoyas.com"
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Juli & Majo",
    participants: [
      "majo.dejuan@secuoyas.com",
      "julieta.kozlowski@secuoyas.com"
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Fer & Orencio",
    participants: [
      "fernando.demorais@secuoyas.com",
"orencio.ramirez@secuoyas.com"
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Ana G. & Diana",
    participants: [
      "ana.gonzalez@secuoyas.com",
      "diana.hidalgo@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Gloria & Jorge",
    participants: [
      "gloria.rodrigo@secuoyas.com",
      "jorge.fernandez@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Gonzalo H. & Diego",
    participants: [
      "gonzalo.hernandez@secuoyas.com",
"diego.bejar@secuoyas.com"
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Isaac & Gonzalo V.",
    participants: [
      "gonzalo.villar@secuoyas.com",
      "isaac.morcillo@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Alba & Sergio",
    participants: [
      "alba.lopez@secuoyas.com",
      "sergio.rodenas@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Álvaro & Dani R.",
    participants: [
      "alvaro.sanchez@secuoyas.com",
      "daniel.rossello@secuoyas.com"
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Enrique & Maite",
    participants: [
      "maite.antolin@secuoyas.com",
      "enrique.sanz@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
];

export { GROUPS };
